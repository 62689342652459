.switchCurrencyDropdown {
  width: 100%;
  &__container {
    width: 100%;
    &_card {
      width: 100%;
      display: flex;
      padding: 30px 20px;
      justify-content: center;
      align-items: center;
      gap: 75px;
      flex-shrink: 0;
      border-radius: 24px;
      border: 2px solid rgba(255, 255, 255, 0.5);
      background: linear-gradient(180deg, #fff -80.43%, #dfc5ff 91.64%),
        linear-gradient(180deg, #fff 4%, #ecddff 91.89%), #fff;
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
      &:hover {
        cursor: pointer;
      }
      &__currency {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        &__icon {
          width: 50px;
          height: 50px;
          img {
            border-radius: 50%;
            width: 50px;
            height: 50px;
          }
        }
        &__text {
          width: 100%;
          color: #6d05d1;
          margin-left: 10px;
          font-family: 'Gotham';
          &__title {
            font-size: 20px;
            font-weight: 500;
            text-edge: cap;
            margin: 0;
          }
          &__subtitle {
            font-family: 'Gotham';
            font-size: 14px;
            font-weight: 350;
            font-style: normal;
            opacity: 0.7;
          }
        }
      }
      &__change {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        border-bottom: 1px dashed #6d05d1;
        &__text {
          font-family: 'Gotham';
          color: #6d05d1;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
        }
        img {
          width: 10px;
          height: 10px;
        }
      }
    }
    &_dropdown {
      margin-top: 5px;
      z-index: 1000;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      padding: 20px;
      justify-content: center;
      align-items: center;
      gap: 15px;
      flex-shrink: 0;
      border-radius: 24px;
      border: 2px solid rgba(255, 255, 255, 0.5);
      background: linear-gradient(180deg, #fff -80.43%, #dfc5ff 91.64%),
        linear-gradient(180deg, #fff 4%, #ecddff 91.89%), #fff;
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
      width: 88%;
      .selectedCurrency {
        background: #8c37dc !important;
        color: white !important;
      }
      &__item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 20px;
        padding: 10px 20px;
        background: #fff;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
        &__currency {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          &__icon {
            width: 50px;
            height: 50px;
            img {
              border-radius: 50%;
              width: 50px;
              height: 50px;
            }
          }
          &__text {
            width: 100%;
            color: #6d05d1;
            margin-left: 10px;
            font-family: 'Gotham';
            &__title {
              font-size: 20px;
              font-weight: 500;
              text-edge: cap;
              margin: 0;
            }
            &__subtitle {
              font-family: 'Gotham';
              font-size: 14px;
              font-weight: 350;
              font-style: normal;
              opacity: 0.7;
            }
          }
        }
        &:hover {
          cursor: pointer;
        }
        &__status {
          display: flex;
          flex-flow: row-reverse;
          font-size: 12px;
          width: 100%;
          font-family: 'Gotham';
          font-weight: 350;
          background: -webkit-linear-gradient(rgba(255, 153, 0, 1), rgba(255, 0, 214, 1));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      &__title {
        width: 100%;
        font-family: 'Gotham';
        color: #6d05d1;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        margin: 0;
      }
      @media (max-width: $breakPointMd) {
        width: 85%;
      }
      @media (max-width: $breakPointXss) {
        width: 83%;
      }
    }
  }
}

.switchCurrencyCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 20px 30px;
  border-radius: 28px;
  background: rgba(104, 32, 181, 0.5);
  backdrop-filter: blur(10px);
  &__text {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    &__title {
      color: #fff;
      text-align: center;
      font-family: 'Gotham';
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 49.5px;
      letter-spacing: -0.48px;
    }
  }
  &__divider {
    width: 100%;
    height: 1px;
    background: #fff;
    opacity: 0.2;
    margin: 30px 0px;
  }
  &__timer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 20px;
    text-align: center;
    &__text {
      color: #fff;
      font-family: 'Gotham';
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      opacity: 0.8;
    }
    &__time {
      color: #fadf2c;
      font-family: 'Gotham';
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
    }
  }
  &__connect {
    width: 100%;
    text-align: center;
    &__text {
      color: #fff;
      text-align: center;
      font-family: 'Gotham';
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      opacity: 0.8;
      margin-bottom: 10px;
    }
    &__connectedAddress {
      border-radius: 16px;
      background: rgba(0, 0, 0, 0.1);
      padding: 20px;
      &__title {
        color: #fff;
        text-align: center;
        font-family: 'Gotham';
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        margin-bottom: 10px;
      }
      &__address {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
        &_text {
          color: #fff;
          font-family: 'Gotham';
          font-size: 14px;
          font-weight: 350;
          font-style: normal;
          opacity: 0.7;
          @media (max-width: $breakPointMd) {
            word-break: break-all;
          }
        }
      }
      button {
        border-radius: 16px;
        border: 2px solid rgba(255, 255, 255, 0.2);
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
        background: transparent;
      }
    }
  }
  @media (max-width: $breakPoint4xl) {
    width: 50% !important;
  }
  @media (max-width: $breakPoint3xl) {
    width: 85% !important;
  }
  @media (max-width: $breakPointSm) {
    width: 100% !important;
  }
}

.gameBalance {
  margin-top: 30px;
  padding: 35px 20px;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 20px;
  &__icon {
    border-radius: 50%;
    background: #ae2bff;
    mix-blend-mode: lighten;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 70px;
      height: 70px;
      object-fit: contain;
    }
  }
  &__text {
    font-family: 'Gotham';
    &__title {
      font-size: 18px;
      font-weight: 350;
      color: #fff;
      opacity: 0.7;
    }
    &__balance {
      display: flex;
      gap: 15px;
      margin-top: 5spx;
      &__currency {
        font-size: 16px;
        font-weight: 350;
        color: #fff;
        opacity: 0.7;
        align-self: center;
      }
      &__amount {
        opacity: 1;
        font-size: 32px;
        font-weight: 500;
        color: #fff;
      }
    }
  }
}
