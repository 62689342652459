.footer{
  background: rgba(0, 0, 0, 0.50);
  flex-shrink: 0;
  font-family: "Gotham";
  font-weight: 300;
    &__content{
    padding: 50px 100px 50px 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-logo{
      width: 60%;
      img{
        width: 150px;
        object-fit: cover;
      }
      &-text{
        color: rgba(255, 255, 255, 0.70);
        font-size: 1rem;
        width: 50%;
        font-weight: 300;
        margin-top: 10px;
        @media (max-width: $breakPointXs) {
          width: 100%;
        } 
      }
    }
    &-links{
      display: flex;
      color: white;
      gap: 75px;
      &-item{
        display: flex;
        flex-direction: column;
        gap: 10px;
        &_link{
          color: white;
          font-size: 1rem;
          font-weight: 300;
          text-decoration: none;
          transition: all 0.3s ease-in-out;
          &:hover{
            color: #1d80f7;
            cursor: pointer;
          }
        }
      }
      @media (max-width: $breakPointSm) {
        display: -webkit-inline-box;
      } 
      @media (max-width: $breakPointXs) {
        display: flex;
        flex-direction: column;
      } 
    }
    @media (max-width: $breakPointSm) {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 25px;
      padding: 20px;
      width: 100%;
    } 
  }
  &__bottom {
    padding: 0 80px;
    &-content {
      display: flex;
      color: white;
      gap: 20px;
      padding: 15px;
      &-item{
        color: rgb(198, 196, 196);
        font-size: 0.7rem;
        font-weight: 350;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        &:hover{
          color: #1d80f7;
          cursor: pointer;
        }
      }
      @media (max-width: $breakPointSm) {
        display: -webkit-inline-box;
      } 
      @media (max-width: $breakPointXs) {
        display: flex;
        flex-direction: column;
      } 
    }
    @media (max-width: $breakPointXs) {
      padding: 10px;
    } 
  }
  @media (max-width: $breakPointSm) {
    height: auto;
  } 
}