@import url('https://fonts.googleapis.com/css2?family=Lilita+One&family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@font-face {
  font-family: 'ARCO';
  src: url('./ARCO.otf') format('opentype');
}
@font-face {
  font-family: 'Gotham';
  src: url('./Gotham/GothamRnd-Medium.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./Gotham/GothamRnd-Bold.otf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./Gotham/GothamRnd-Light.otf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./Gotham/GothamRnd-Medium.otf') format('truetype');
  font-weight: 350;
  font-style: normal;
}

html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  background: #3c007a;
  margin: 0;
  padding: 0;
}

.app {
  background: #3c007a;
  color: white;
  min-height: 100vh;
  position: relative;
  width: 100%;
  height: 100%;
  .circle {
    position: absolute;
    margin-top: 10vh;
    width: 100%;
    height: 90vh;
    background: linear-gradient(180deg, #e807fb -69.14%, #8a0495 100%);
    filter: blur(150px);
    mix-blend-mode: lighten;
    border-radius: 89.375rem;
    opacity: 0.2;
    z-index: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden !important;
  font-family: 'Quicksand';
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  z-index: 1;
}

main {
  flex: 1 0 auto;
}

.autoLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

@import 'variables.scss';

// pages
@import './pages/home.scss';
@import './pages/deposit.scss';
@import './pages/withdraw.scss';
@import './pages/admin.scss';
@import './pages/profile.scss';
@import './pages/faq.scss';
@import './pages/privacyPolicy.scss';

// components
@import './components/button.scss';
@import './components/footer.scss';
@import './components/modal.scss';
@import './components/login.scss';
@import './components/confirmModal.scss';
@import './components/depositError.scss';
@import './components/loginSuccessfulModal.scss';
@import './components/selectGame.scss';
@import './components/switchCurrency.scss';
@import './components/depositPackages.scss';
@import './components/loading.scss';
@import './components/processing.scss';
@import './components/confirmWithdraw.scss';
@import './components/navbar.scss';
