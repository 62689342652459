.deposit-error {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  color: #3c017a;
  font-family: 'Gotham';
  &__text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    text-align: center;
    &_title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.4rem;
      font-weight: 500;
      margin-bottom: 1rem;
      gap: 10px;
    }
    &_subtitle {
      font-size: 1rem;
      font-weight: 300;
      opacity: 0.8;
      margin-bottom: 1rem;
    }
  }
  &__button-container {
  }
}
