.login-successful {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  color: #3C017A;
  font-family: Quicksand;
  &-title{
    font-size: 24px;
    font-weight: 600;
  }
  &-content {
    width: 50%;
    text-align: center;
  }
  &-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 100%;
    .button {
      width: 35%;
    }
  }
}