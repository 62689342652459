.home {
  width: 100%;
  z-index: 99;
  &__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    margin-top: 50px;
    @media (min-width: 768px) {
      padding: 0 2rem;
    }
    @media (min-width: 1024px) {
      padding: 0 4rem;
    }
    @media (min-width: 1440px) {
      padding: 0 8rem;
    }
    @media (min-width: 1920px) {
      padding: 0 12rem;
    }
    &__text {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 50%;
      padding-bottom: 100px;
      &__title {
        width: 100%;
        font-family: 'Gotham';
        font-size: 40px;
        @media (max-width: $breakPointSm) {
          font-weight: 500;
          font-size: 2.2rem;
          text-align: center;
          height: 18vh;
        }
      }
      &__subtitle {
        font-family: 'Gotham';
        font-weight: 300;
        width: 95%;
        font-size: 18px;
        color: rgba(255, 255, 255, 0.7);
        @media (max-width: $breakPointSm) {
          font-weight: 300;
          font-size: 1rem;
          text-align: center;
          width: 100%;
          height: 15vh;
        }
      }
      &__download {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
        height: 15vh;
        &__button {
          width: 100%;
          align-self: self-start;
          cursor: pointer;
          button {
            width: 29vw;
            @media (max-width: $breakPointSm) {
              width: 90vw;
            }
          }
          @media (max-width: $breakPointSm) {
            max-width: 100%;
            margin-top: 1px;
          }
        }
        &__store {
          display: flex;
          align-self: self-start;
          width: 29vw;
          margin-top: 1rem;
          a {
            width: fit-content;
            img {
              width: 15vw;
              height: fit-content;
              object-fit: contain;
              margin-right: 1rem;
              @media (max-width: $breakPointSm) {
                width: 45vw;
              }
            }
            .apple {
              width: 13vw;
              height: fit-content;
              @media (max-width: $breakPointSm) {
                width: 50vw;
              }
            }
            &:hover {
              z-index: 5;
              cursor: pointer;
            }
          }
          @media (max-width: $breakPointSm) {
            display: flex;
            justify-content: center;
            align-self: center;
            align-items: center;
            margin-left: 3vw;
          }
        }
        @media (max-width: $breakPointSm) {
          margin-top: 1px;
        }
      }
      @media (max-width: $breakPointSm) {
        width: 95%;
        height: 40vh;
        text-align: center;
      }
    }
    &__image {
      width: 50%;
      height: 75vh;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      @media (max-width: $breakPointSm) {
        width: 100%;
        height: 50vh;
      }
    }
    @media (max-width: $breakPointSm) {
      display: flex;
      flex-direction: column-reverse;
      padding: 0px;
      margin: 0px;
    }
  }
}

.gamesCard {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding: 10px 0px;
  position: relative;
  text-align: left;
  &-image {
    object-fit: cover;
    border-radius: 10px;
    display: flex;
    gap: 20px;
    width: 28%;
    img {
      width: 116px;
      height: 116px;
      border-radius: 25px;
    }
    &-text {
      display: flex;
      flex-direction: column;
      gap: 10px;
      &-title {
        font-family: 'Gotham';
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      &-subtitle {
        font-family: Quicksand;
        color: rgba(255, 255, 255, 0.757);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    @media (max-width: $breakPointXs) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
  &-play {
    background: #f1f0ff;
    box-shadow: 0px 0px 5.403px 0px rgba(0, 0, 0, 0.1);
    border-radius: 0.4375rem;
    border: 0.5px solid #080056;
    z-index: 4;
    padding: 5px;
    color: #3d3d3d;
    width: 200px;
    height: 100px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-title {
      text-align: center;
      font-family: Quicksand;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      align-self: center;
      margin-top: 5px;
      margin-bottom: 10px;
    }
    a {
      width: 100%;
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      img {
        padding: 0px;
        width: auto;
        height: 60px;
        object-fit: fill;
        align-self: center;
      }
      .button {
        height: 40px;
        padding: 5px 20px;
        width: 100%;
      }
    }
  }
  @media (max-width: $breakPoint) {
    flex-direction: column;
    &-image {
      width: 520px;
      &-text {
        width: 100%;
        &-title {
          font-size: 20px;
        }
        &-subtitle {
          width: 80%;
          font-size: 12px;
        }
      }
    }
    &:after {
      width: 100%;
    }
  }
  @media (max-width: $breakPointXs) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 0px;
    &-image {
      width: 100%;
      &-text {
        width: 100%;
        &-title {
          font-size: 20px;
        }
        &-subtitle {
          width: 80%;
          font-size: 12px;
        }
      }
    }
    &-plays {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      height: 100%;
    }
    &-play {
      width: 100%;
      height: 120px;
      button {
        width: 100%;
      }
      a {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
    &:after {
      width: 90%;
    }
  }
}

.gamesCard-plays {
  gap: 10px;
  display: flex;
  z-index: 5;
  justify-content: center;
}
