.withdraw {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  margin-bottom: 50px;
  &__card {
    width: 60%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    border-radius: 28px;
    background: rgba(104, 32, 181, 0.5);
    backdrop-filter: blur(10px);
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px 30px;
      gap: 20px;
      width: 100%;
      .switchCurrencyDropdown__container_dropdown {
        @media (max-width: $breakPoint4xl) {
          width: 50% !important;
        }
        @media (max-width: $breakPoint3xl) {
          width: 63% !important;
        }
        @media (max-width: $breakPointSm) {
          width: 95% !important;
        }
      }
      &__text {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
        &__title {
          color: #fff;
          text-align: center;
          font-family: 'Gotham';
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 49.5px;
          letter-spacing: -0.48px;
        }
      }
      &__divider {
        width: 100%;
        height: 1px;
        background: #fff;
        opacity: 0.2;
        margin: 30px 0px;
      }
      &__connect {
        width: 100%;
        text-align: center;
        &__crypto {
          margin: 30px 0;
          &__text {
            color: #fff;
            text-align: center;
            font-family: 'Gotham';
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            opacity: 0.8;
            margin-bottom: 10px;
          }
        }
        &__connectedAddress {
          border-radius: 16px;
          background: rgba(0, 0, 0, 0.1);
          padding: 20px;
          margin-bottom: 25px;
          &__title {
            color: #fff;
            text-align: center;
            font-family: 'Gotham';
            font-size: 18px;
            font-weight: 500;
            font-style: normal;
            margin-bottom: 10px;
          }
          &__address {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;
            &_text {
              color: #fff;
              font-family: 'Gotham';
              font-size: 14px;
              font-weight: 350;
              font-style: normal;
              opacity: 0.7;
              @media (max-width: $breakPointMd) {
                word-break: break-all;
              }
            }
          }
          button {
            border-radius: 16px;
            border: 2px solid rgba(255, 255, 255, 0.2);
            box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
            background: transparent;
          }
        }
        &__continue {
          button {
            background: linear-gradient(180deg, #fff -80.43%, #dfc5ff 91.64%),
              linear-gradient(180deg, #1cdf1c 0%, #01ba01 100%);
            box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
            .text {
              color: #6d05d1;
            }
            &:disabled {
              opacity: 0.5;
            }
          }
        }
      }
      @media (max-width: $breakPoint4xl) {
        width: 55% !important;
      }
      @media (max-width: $breakPoint3xl) {
        width: 70% !important;
      }
      @media (max-width: $breakPointSm) {
        width: 100% !important;
      }
      &__address {
        display: flex;
        flex-direction: column;
        gap: 30px;
        &__input {
          &__title {
            color: #fff;
            font-family: 'Gotham';
            font-size: 14px;
            margin-left: 5px;
            font-weight: 400;
            font-style: normal;
            opacity: 0.9;
            span {
              opacity: 1;
              margin: 0px 5px;
            }
          }
          input {
            background: rgba(0, 0, 0, 0.1);
            border-radius: 16px;
            padding: 20px;
            color: #fff;
            font-family: 'Gotham';
            font-size: 14px;
            font-weight: 350;
            font-style: normal;
            opacity: 0.7;
            width: 100%;
            border: none;
            margin-top: 10px;
            &:focus {
              outline: none;
            }
          }
          &--error {
            margin-top: 5px;
            margin-left: 5px;
            color: #ff3737;
            font-family: 'Gotham';
            font-size: 14px;
            font-weight: 350;
            font-style: normal;
          }
        }
        &__fee {
          color: rgba(255, 255, 255, 0.7);
          font-family: 'Gotham';
          font-size: 14px;
          font-weight: 350;
          font-style: normal;
          margin-top: 10px;
          span {
            color: #fff;
            font-family: 'Gotham';
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            opacity: 1;
          }
          a {
            color: #fff;
            font-family: 'Gotham';
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            opacity: 1;
            text-decoration: none;
          }
        }
      }
      @media (max-width: $breakPointSm) {
        width: 100%;
        padding: 10px;
      }
    }
    @media (max-width: $breakPointMd) {
      width: 90%;
    }
    @media (max-width: $breakPointSm) {
      width: 95%;
    }
  }
}

.goBack {
  width: 100%;
  display: flex;
  font-size: 1.2rem;
  margin-left: 50px;
  margin-top: 50px;
  span {
    img {
      width: 20px;
      height: 20px;
      color: white;
    }
  }
  button {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: transparent;
    color: white;
    text-align: center;
    font-family: 'Gotham';
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  &:hover {
    cursor: pointer !important;
  }
}
