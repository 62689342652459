.select-game {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 50%;
    @media (max-width: $breakPointSm) {
      max-width: 100%;
    }
    &-card {
      border-radius: 24px;
      border: 2px solid rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(2px);
      width: 400px;
      height: 250px;
      img {
        width: 100%;
        height: 70%;
        object-fit: fill;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        background: transparent;
      }
      &-bottom {
        background: #ae2bff;
        height: 30%;
        border-end-end-radius: 24px;
        border-end-start-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        text-align: center;
        img {
          width: 28px;
          height: 28px;
          margin-right: 1rem;
          margin-bottom: 1vh;
          object-fit: contain;
        }
        &-title {
          color: #fff;
          text-align: center;
          font-family: 'ARCO';
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          text-align: center;
          margin-left: 2rem;
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}
