.faq {
  display: flex;
  justify-content: center;
  padding: 50px;
  &__title {
    font-size: 2rem;
    margin-bottom: 1rem;
    margin-left: 5rem;
    width: 30%;
    @media (max-width: $breakPointSm) {
      width: 100%;
      margin-left: 1rem;
    }
  }
  &__questions {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    width: 50%;
    &__question {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid #e0c6ff;
      &:last-child {
        border-bottom: none;
      }
      padding: 1rem 0;
      &:hover {
        cursor: pointer;
      }
      &__text {
        transition: all 0.3s ease-in-out;
        &-title {
          font-size: 20px;
          font-weight: 400;
          margin-bottom: 1rem;
        }
        &-subtitle {
          transition: all 0.3s ease-in-out;
          font-size: 18px;
          margin-bottom: 1rem;
          font-weight: 300;
          color: rgba(255, 255, 255, 0.7);
          a {
            color: #e0c6ff;
            opacity: 0.9;
            text-decoration: none;
            &:hover {
              color: #e0c6ff;
              text-decoration: underline;
            }
          }
        }
      }
      &__icon {
        font-size: 3rem;
        font-weight: 600;
        cursor: pointer;
        transition: transform 0.3s;
        &:hover {
          font-size: 3rem;
        }
      }
    }
    @media (max-width: $breakPointSm) {
      width: 100%;
    }
  }
  @media (max-width: $breakPointSm) {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
}
