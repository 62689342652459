.withdraw-confirm {
  color: black;
  color: #3c017a;
  &__title {
    text-align: center;
    color: #3c017a;
    text-align: center;
    font-family: 'Gotham';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.8;
  }
  &__content {
    &__card {
      background: rgba(0, 0, 0, 0.1);
      padding: 20px 20px;
      border-radius: 16px;
      margin: 20px 0px;
      &__item {
        display: flex;
        align-items: center;
        &__text {
          margin-left: 15px;
          margin-top: 5px;
          &__title {
            color: #3c017a;
            font-family: 'Gotham';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            opacity: 0.8;
          }
          &__currency {
            color: #3c017a;
            font-family: 'Gotham';
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            opacity: 0.8;
          }
          &__address {
            margin-top: 5px;
            color: #3c017a;
            font-family: 'Gotham';
            font-size: 14px;
            font-style: normal;
            font-weight: 350;
            word-break: break-all;
            opacity: 0.6;
          }
        }
      }
      &__divider {
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin: 10px 20px;
      }
    }
    &__note {
      padding: 10px 0px;
      color: rgba(60, 1, 122, 0.5);
      text-align: center;
      font-family: 'Gotham';
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      border-radius: 4px;
      background: #ffedc5;
      margin-bottom: 20px;
    }
    &__fee {
      border-radius: 16px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      background-color: rgba(0, 0, 0, 0.1);
      padding: 20px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      &__text {
        color: #3c017a;
        font-family: 'Gotham';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        opacity: 0.8;
        display: flex;
        justify-content: space-between;
      }
      &__divider {
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin: 10px 0px;
      }
    }
    &__buttons {
      display: flex;
      gap: 10px;
    }
  }
}
