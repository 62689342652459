@font-face {
  font-family: 'Insaniburger with Cheese';
  src: url('/Insanibc.ttf') format('truetype');
}

*,
::after,
::before {
  box-sizing: border-box;
}

.button {
  width: 100%;
  color: #fff;
  text-align: center;
  font-family: ARCO;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(180deg, #1cdf1c 0%, #01ba01 100%);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
  border: none;
  padding: 15px 7px;
  border-radius: 1rem;
  &:hover {
    cursor: pointer;
  }
}

.google {
  font-family: 'Quicksand', sans-serif;
  background: #5184ec;
  color: white;
  box-shadow: none;
  outline: none;
  text-shadow: none;
  border: none;
  border-radius: 15px;
  .text {
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px 15px;
  }
}

.apple {
  font-family: 'Quicksand', sans-serif;
  background: #000000;
  color: white;
  box-shadow: none;
  outline: none;
  text-shadow: none;
  border: none;
  border-radius: 15px;
  .text {
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px 15px;
  }
}

.facebook {
  font-family: 'Quicksand', sans-serif;
  background: #405996;
  color: white;
  box-shadow: none;
  outline: none;
  text-shadow: none;
  border: none;
  border-radius: 15px;
  .text {
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px 15px;
  }
}

.red {
  background: linear-gradient(180deg, #ff579f 0%, #ff3d85 100%);
}

.outline {
  background: white;
  border: 2px solid #8c37dc;
  color: #8c37dc;
}

.purple {
  background: #8c37dc;
  color: white;
}
