.modal-container {
  overflow: overlay;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.darkBG {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.TransparentBG {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200vw;
  height: 200vh;
  background-color: transparent;
  z-index: 9;
}

.modal {
  width: 450px;
  border-radius: 5px;
  z-index: 9999;
  position: absolute;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 9999;
  border-radius: 9px;
  background: #e4e4e4;
  &-content {
    border-radius: 10px;
    padding: 20px;
    background-color: #fff;
  }
  @media (max-width: 768) {
    width: 60%;
  }
  @media (max-width: 450px) {
    width: 90%;
  }
}
