.privacy-policy {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__title {
    margin: 50px;
    font-size: 36px;
    font-weight: 800;
    text-align: center;
  }
  &__content {
    width: 75%;
    margin-bottom: 50px;
    &_title {
      font-size: 36px;
      font-weight: 700;
      margin: 20px 0;
    }
    &_subtitle {
      font-size: 24px;
      font-weight: 600;
    }
    &_text {
      font-size: 18px;
      font-weight: 500;
      margin: 10px 0;
    }
  }
}
