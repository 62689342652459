.deposit__packages {
  display: flex;
  flex-direction: column;
  align-self: baseline;
  width: 100%;
  &__title {
    color: #fff;
    font-family: 'Gotham';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
  }
  &__divider {
    width: 100%;
    height: 1px;
    background: #fff;
    opacity: 0.2;
    margin: 20px 0px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    &__card {
      width: 225px;
      height: 190px;
      flex-shrink: 0;
      border: 3px solid rgba(255, 255, 255, 0.5);
      background: linear-gradient(180deg, #fff -80.43%, #dfc5ff 91.64%),
        linear-gradient(180deg, #fff 4%, #ecddff 91.89%), #fff;
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
      border-radius: 24px;
      &:hover {
        cursor: pointer;
      }
      &__offer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        font-family: 'Gotham';
        height: 75%;
        gap: 5px;
        &__currency {
          color: rgba(109, 5, 209, 0.5);
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        &__bonus {
          background: linear-gradient(90deg, #f90 0%, #ff00d6 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-top: 2vh;
        }
        &__amount {
          color: #6d05d1;
          text-align: center;
          font-family: 'Gotham';
          font-size: 28px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      &__button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 25%;
        background: linear-gradient(90deg, #b764ff 0.28%, #7800e2 99.72%);
        z-index: 5;
        border-end-end-radius: 24px;
        border-end-start-radius: 24px;
        &:hover {
          cursor: pointer;
        }
        &__text {
          color: #eee0ff;
          text-align: center;
          font-family: 'Gotham';
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-left: 1rem;
        }
        img {
          margin-right: 5px;
          height: 35%;
        }
      }
      @media (max-width: $breakPointSm) {
        width: 180px;
        height: 170px;
      }
    }
    @media (max-width: $breakPointMd) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 15px;
    }
  }
}
