.profile {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: white;
  .profile-left-bar {
    width: 20%;
    background: #ebecff;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    &__item {
      padding: 20px 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      a {
        color: #656565;
        text-decoration: none;
        font-size: 18px;
        font-weight: 600;
        &:hover {
          color: #000;
        }
      }
      img {
        width: 20px;
        margin-right: 10px;
      }
    }
  }
  &-transactions {
    width: 75%;
    padding: 50px 100px;
    &__header {
      margin-bottom: 20px;
      &__title {
        align-self: start;
        font-size: 28px;
        font-weight: 600;
        color: white;
      }
    }
    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      &__item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.123);
        border-radius: 1px;
        padding: 20px;
        &__type {
          display: flex;
          align-items: center;
          gap: 20px;
          &__icon {
            width: 50px;
            height: 50px;
            border-radius: 25px;
            color: white;
            img {
              width: 50px;
              height: 50px;
              object-fit: cover;
            }
            @media (max-width: $breakPointSm) {
              min-width: 50px;
              align-items: center;
            }
          }
          &__text {
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;
            gap: 5px;
            font-family: 'Gotham';
            &__title {
              align-self: flex-start;
              font-size: 18px;
              font-weight: 400;
              color: white;
              @media (max-width: $breakPointSm) {
                align-self: center;
              }
            }
            &__subtitle {
              align-self: flex-start;
              font-size: 16px;
              font-weight: 350;
              color: #ffffffbe;
              @media (max-width: $breakPointSm) {
                align-self: center;
              }
            }
            &__date {
              font-size: 14px;
              font-weight: 300;
              color: white;
              opacity: 0.8;
              align-self: self-start;
            }
            @media (max-width: $breakPointSm) {
              width: 75%;
            }
          }
          @media (max-width: $breakPointSm) {
            align-items: center;
          }
        }
        &__amount {
          font-size: 20px;
          font-weight: 500;
          color: white;
          span {
            font-weight: 400;
            font-size: 16px;
            color: white;
            opacity: 0.6;
          }
        }
        @media (max-width: $breakPointSm) {
          gap: 20px;
        }
      }
    }
    @media (max-width: $breakPointMd) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 5px;
      width: 75%;
    }
    @media (max-width: $breakPointSm) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 5px;
      width: 100%;
    }
  }
}
