.confirm {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  color: #3c017a;
  font-family: 'Gotham';
  &-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    img {
      margin-top: 5px;
      width: 30px;
      height: 30px;
    }
  }
  &-balance {
    font-size: 2.4rem;
    font-weight: 500;
    text-align: center;
    span {
      font-size: 2.4rem;
      font-weight: 700;
    }
  }
  &-content {
    font-size: 1rem;
    font-weight: 300;
    opacity: 0.8;
    text-align: center;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    span {
      font-size: 1rem;
      font-weight: 700;
    }
  }
  &-info {
    background: #c5ffdc;
    padding: 10px;
    border-radius: 10px;
    font-size: 0.8rem;
    color: #5fa579;
    margin-bottom: 1rem;
    a {
      color: #4c9467;
      font-weight: 700;
    }
  }
  &-warning {
    background: #ffedc5;
    padding: 10px;
    border-radius: 10px;
    font-size: 0.8rem;
    color: #deab50;
    margin-bottom: 1rem;
    font-weight: 700;
    a {
      color: #dba647;
      font-weight: 900;
    }
  }
  &-button {
    display: flex;
    justify-content: center;
    width: 50%;
  }
  &-buttons {
    display: flex;
    justify-content: center;
    gap: 25px;
    width: 100%;
  }
}
