.admin-dashboard {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: white;
  &-titles {
    display: flex;
    justify-content: space-around;
    padding: 0px 20px;
    &-title {
      flex: 1;
      padding: 10px;
      font-weight: bold;
      align-self: flex-end;
    }
  }
}
.request-list {
  gap: 10px;
  padding: 0px 20px;
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    &__item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f7f7f717;
      border-radius: 1px;
      padding: 20px;
      &__type {
        display: flex;
        gap: 20px;
        &__icon {
          width: 50px;
          height: 50px;
          border-radius: 25px;
          background: #ffe0bb;
          img {
            width: 100%;
          }
          @media (max-width: $breakPointSm) {
            min-width: 50px;
            align-items: center;
          }
        }
        &__text {
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: center;
          gap: 5px;
          margin-top: 5px;
          &__title {
            align-self: flex-start;
            font-size: 18px;
            font-weight: 600;
          }
          &__amount {
            font-size: 18px;
            font-weight: 700;
            color: #adadad;
            align-self: self-start;
          }
          @media (max-width: $breakPointSm) {
            width: 75%;
          }
        }
        @media (max-width: $breakPointSm) {
          align-items: center;
        }
      }
      &__buttons {
        display: flex;
        gap: 20px;
      }
      @media (max-width: $breakPointSm) {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}
