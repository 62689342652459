.deposit {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  min-height: 60vh;
  padding: 50px 100px;
  width: 100%;
  @media (max-width: $breakPoint) {
    padding: 50px 20px;
  }
  @media (max-width: $breakPointMd) {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  @media (max-width: $breakPointSm) {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 20px;
  }
  &__currency {
    width: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: $breakPointMd) {
      align-items: center;
    }
    p {
      font-size: 1rem;
      font-weight: 600;
      opacity: 0.7;
      margin-bottom: 1rem;
      width: 100%;
      text-align: center;
      z-index: -5;
      @media (max-width: $breakPoint4xl) {
        width: 50% !important;
      }
      @media (max-width: $breakPoint3xl) {
        width: 85% !important;
      }
      @media (max-width: $breakPointSm) {
        width: 100% !important;
      }
    }
  }
}
