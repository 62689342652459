.navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: url('/Rectangle 22.png');
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 10vh;
  flex-shrink: 0;
  transition: all 0.3s ease-in-out;
  font-family: 'Gotham';
  font-weight: 400;
  &__menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    @media (min-width: 768px) {
      padding: 0 2rem;
    }
    @media (min-width: 1024px) {
      padding: 0 4rem;
    }
    @media (min-width: 1440px) {
      padding: 0 8rem;
    }
    @media (min-width: 1920px) {
      padding: 0 12rem;
    }
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 3rem;
      z-index: 5;
      &_logo {
        max-width: 150px;
        object-fit: contain;
        margin-right: 25px;
        img {
        }
      }
      &-connect {
        min-width: 120px;
        a {
          text-decoration: none;
          padding: 0px 10px;
        }
      }
      &_links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2.5rem;
        transition: all 0.3s ease-in-out;
        z-index: 3;
        a {
          color: $colorWhite;
          font-size: 1rem;
          font-style: normal;
          font-weight: 350;
          line-height: 150%;
          text-decoration: none;
          padding: 6px 10px;
          border-radius: 10px;
          z-index: 3;
          &:hover {
            background: #560c63;
          }
        }
        .active {
          border-radius: 0px;
          border-bottom: 1px solid $colorWhite;
        }
        img {
          &:hover {
            cursor: pointer;
          }
        }
        @media (max-width: $breakPointXl) {
          gap: 15px;
        }
      }
      &__disconnect {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        color: $colorWhite;
        &:hover {
          cursor: pointer;
        }
        &-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;
        }
        @media (max-width: $breakPointSm) {
          gap: 10px;
          &-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
          }
        }
      }
      &-address {
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        gap: 5px;
        font-size: 0.8rem;
        font-weight: normal;
        margin-left: 5px;
        color: white;
        text-align: center;
        @media (max-width: $breakPointSm) {
          display: none;
        }
      }
    }
  }
  &--extended {
    width: 75%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    justify-content: start;
    overflow-y: hidden;
    transition: all 0.4s ease-in-out;
    background-color: rgba(104, 32, 181, 0.5);
    backdrop-filter: blur(50px);
    z-index: 999999999;
    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 2rem;
      z-index: 2;
      &:hover {
        cursor: pointer;
      }
    }
    .navbar__menu {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-left: 50px;
    }
    .navbar__menu-item_links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 25px;
      text-align: left;
      width: 100%;
      a {
        width: 100%;
        text-align: left;
        border-radius: 0px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        position: relative;
        &::before {
          content: '';
          background-image: url('/star.svg');
          background-repeat: no-repeat;
          background-size: cover;
          width: 10px;
          height: 10px;
          position: absolute;
          top: 29%;
          left: -5%;
        }
      }
    }
    .navbar__menu-item {
      width: 100%;
    }
    .navbar__menu-item__disconnect {
      p {
        font-size: 1rem;
        font-weight: 600;
      }
    }
    @media (max-width: $breakPoint) {
      &::before {
        background: none;
      }
    }
  }
  @media (max-width: $breakPointMd) {
    &::before {
      background: none;
    }
  }
  @media (max-width: $breakPointSm) {
    padding: 10px;
  }
}
