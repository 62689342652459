.login{
  display: flex;
  padding: 0px 100px;
  width: 100%;
  &__image{
    width: 50%;
    height: fit-content;
    align-self: flex-start;
    margin-top: 2rem;
    img{
      width: 100%;
      height: 60vh;
      object-fit: contain;
    }
    @media (max-width: $breakPointSm) {
      width: 100%;
      height: 45vh;
      margin-top: 0;
      img{
        height: 100%;
      }
    } 
  }
  &__card{
    width: 40%;
    height: fit-content;
    margin-top: 100px;
    margin-left: 100px;
    border-radius: 1.75rem;
    background: rgba(104, 32, 181, 0.50);
    backdrop-filter: blur(10px);
    font-family: "Gotham";
    padding: 50px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
    &__title{
      color: white;
      font-size: 1.5rem;
      font-weight: 500;
    }
    &__subtitle{
      color: white;
      width: 70%;
      text-align: center;
      opacity: 0.7;
      font-size: 1rem;
      font-weight: 300;
    }
    &__social{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
      &-button{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        button{
          border: none;
          display: flex;
          width: 21.75rem;
          height: 3.25rem;
          padding: 0.75rem 2.3125rem;
          justify-content: center;
          align-items: center;
          gap: 0.625rem;
          border-radius: 1rem;
          background: #F2E7FF;
          color: #3C017A;
          text-align: center;
          font-family: "Gotham";
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          &:hover{
            cursor: pointer;
          }
        }
      }
    }
    @media (max-width: $breakPoint) {
      width: 50%;
    } 
    @media (max-width: $breakPointSm) {
      width: 100%;
      margin: 0;
      padding: 25px;
    } 

  }
  @media (max-width: $breakPointSm) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 15px;
    margin: 2rem 0;
  } 
}